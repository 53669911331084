export const _leadhtml1 = 
' ' +
    '<td align="center" height="100%" valign="top" width="100%"><!--[if mso]> ' +
                '<table align="center" border="0" cellspacing="0" cellpadding="0" width="640"> ' +
                '<tr>' +
                '<td align="center" valign="top" width="640">' +
                '<![endif]-->' +
    '<table align="center" border="0" cellpadding="0" cellspacing="0" style="max-width: 640px; border-collapse: collapse; border-spacing: 0;" width="100%">' +
        '<tbody>' +
         '   <tr>' +
          '      <td align="center" bgcolor="fcfcfc" style="border-top-width: 1px; border-top-style: solid; border-top-color: #eeeeee; border-bottom-style: solid; border-bottom-width: 2px; border-bottom-color: #eeeeee; border-radius: 4px; font-size: 0; overflow: hidden;" valign="top">' +
           '     <div><a href="' ;
    
export const _leadhtml2 = 
               '?ltflags=mailer&amp;utm_medium=email&amp;utm_campaign=extremism-watch-t161&amp;utm_source=newsletter"><img border="0" src="';
    
// image
export const _leadhtml3 = '" style="display: block; height: auto !important; width: 100% !important; border: 0;" /></a></div> ' +
               ' <table border="0" cellpadding="15" cellspacing="0" style="border-collapse: collapse; border-spacing: 0; font-size: 0; width: 100%; margin: 0; border: 0;" width="100%">' +
               '     <tbody>' +
               '         <tr>' +
               '             <td bgcolor="#fcfcfc" style="font-size: 0; width: 100%; margin: 0;" width="100%">' +
               '             <table border="0" cellpadding="8" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;">' +
               '                 <tbody>' +
               '                     <tr>' +
               '                         <td bgcolor="#e9e9e9" style="color: #666666; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 12px; letter-spacing: .015em; line-height: 1; text-transform: uppercase;">';

// content type
export const _leadhtml4 ='                                   </td>' +
                                    '</tr>' +
                                '</tbody>' +
                            '</table>' +
                            '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                            '   <tbody>' +
                            '        <tr>' +
                            '            <td height="8" style="font-size: 0;">&nbsp;</td>' +
                            '        </tr>' +
                            '    </tbody>' +
                            '</table>' +
                            '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                            '    <tbody>' +
                            '        <tr>' +
                            '            <td style="color: #1330bf; margin: 0; padding: 0; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-weight: bold; text-decoration: none; font-size: 24px; line-height: 1.35;"><a href="';


//<%= article.url %>
export const _leadhtml5 ='?ltflags=mailer&amp;utm_medium=email&amp;utm_campaign=extremism-watch-t161&amp;utm_source=newsletter" style="color: #1330bf; margin: 0; padding: 0; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-weight: bold; text-decoration: none; font-size: 24px; line-height: 1.35;">';


//<%= article.title %>
export const _leadhtml6 = '</a></td>' +
                         '           </tr>' +
                         '       </tbody>' +
                         '   </table>' +
                         '   <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                         '       <tbody>' +
                         '           <tr>' +
                         '               <td height="8" style="font-size: 0;">&nbsp;</td>' +
                         '           </tr>' +
                         '       </tbody>' +
                         '   </table>' +
                         '   <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                         '       <tbody>' +
                         '           <tr>' +
                         '               <td style="color: #999999; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 13px; letter-spacing: .01em; padding: 0; margin: 0; text-transform: uppercase;">';
 //<%= article.pubtime %> | <%= article.author %>
export const _leadhtml7 = '</td>' +
                          '          </tr>' +
                          '      </tbody>' +
                          '  </table>' +
                          '  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                          '      <tbody>' +
                          '          <tr>' +
                          '              <td height="6" style="font-size: 0;">&nbsp;</td>' +
                          '          </tr>' +
                          '      </tbody>' +
                          '  </table>' +
                          '  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                          '      <tbody>' +
                          '          <tr>' +
                          '              <td style="color: #444444; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; text-decoration: none; font-size: 17px; padding: 0px; margin: 0; line-height: 1.35;">';
                
                
//<%= article.description %>
export const _leadhtml8 = '</td>' +
                          '          </tr>' +
                          '      </tbody>' +
                          '  </table>' +
                          '  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                          '      <tbody>' +
                          '          <tr>' +
                          '              <td height="16" style="font-size: 0;">&nbsp;</td>' +
                          '          </tr>' +
                          '      </tbody>' +
                          '  </table>' +
                          '  <!--[if mso]>' +
                          '              <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="';
               
//https://www.voanews.com/extremism-watch/turkish-military-sees-opportunity-vulnerable-and-war-torn-places
export const _leadhtml9 = '?ltflags=mailer&utm_medium=email&utm_campaign=extremism-watch-t161&utm_source=newsletter" style="height:35px;v-text-anchor:middle;width:150px;" arcsize="5%" strokecolor="#1330bf" fillcolor="#fcfcfc">' +
                          '                  <w:anchorlock/>' +
                          '                  <center style="color:#1330bf;font-family:Helvetica,Tahoma,Verdana,Arial,sans-serif;font-size:15px;">Continue reading</center>' +
                          '              </v:roundrect>' +
                          '              <![endif]-->' +
                          '  <div><a href="';

//<%= article.url %>                
export const _leadhtml10 = '?ltflags=mailer&amp;utm_medium=email&amp;utm_campaign=extremism-watch-t161&amp;utm_source=newsletter" style="background-color: #fff; border: 1px solid #1330bf; border-radius: 4px; color: #1330bf; display: inline-block; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 15px; font-weight: normal; letter-spacing: .015em; line-height: 35px; text-align: center; text-decoration: none; width: 150px; -webkit-text-size-adjust: none; mso-hide: all;">Continue reading</a></div>' +
                            '</td>' +
                        '</tr>' +
                    '</tbody>' +
                '</table>' +
                '</td>' +
            '</tr>' +
        '</tbody>' +
    '</table>' +
    '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
        '<tbody>' +
            '<tr>' +
                '<td height="30" style="font-size: 0;">&nbsp;</td>' +
            '</tr>' +
        '</tbody>' +
    '</table>' +
    '<!--[if mso]>' +
                '</td>' +
                '</tr>' +
                '</table>' +
                '<![endif]--></td>' +
' ';