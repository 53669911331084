export const _html1 = 
'<tr>' +
   '<td align="center" height="100%" valign="top" width="95%"><!--[if mso]>' +
           '<table align="center" border="0" cellspacing="0" cellpadding="0" width="640">' +
           '<tr>' +
           '<td align="center" valign="top" width="640">' +
           '<![endif]-->' +
   '<table align="center" border="0" cellpadding="0" cellspacing="0" style="max-width: 640px; border-collapse: collapse; border-spacing: 0;" width="100%">' +
       '<tbody>' +
           '<tr>' +
               '<td align="center" bgcolor="fcfcfc" style="border-top-width: 1px; border-top-style: solid; border-top-color: #eeeeee; border-bottom-style: solid; border-bottom-width: 2px; border-bottom-color: #eeeeee; border-radius: 4px; font-size: 0; overflow: hidden;" valign="top"><!--[if mso]>' +
                       '<table align="center" border="0" cellspacing="0" cellpadding="0" width="640">' +
                       '<tr>' +
                       '<td align="left" valign="top" width="320">' +
                       '<![endif]-->' +
               '<div style="display: inline-block; max-width: 320px; vertical-align: top; width: 100%;">' +
               '<table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 320px; border-collapse: collapse; border-spacing: 0;" width="100%">' +
                   '<tbody>' +
                       '<tr>' +
                           '<td align="center" valign="top">' +
                           '<div><a href="';

        //<%= article.url %>
        export const _html2 = '?ltflags=mailer&amp;utm_medium=email&amp;utm_campaign=extremism-watch-t161&amp;utm_source=newsletter"><img border="0" src="';
                           
        //<%= article.imgstr %>
        export const _html3 ='" style="display: block; height: auto !important; width: 100% !important; border: 0;" /></a></div>' +
                           '</td>' +
                       '</tr>' +
                   '</tbody>' +
               '</table>' +
               '</div>' +
               '<!--[if mso]>' +
                       '</td>' +
                       '<td align="left" valign="top" width="320">' +
                       '<![endif]-->' +
               '<div style="display: inline-block; max-width: 320px; vertical-align: top; width: 100%;">' +
               '<table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 320px; border-collapse: collapse; border-spacing: 0;" width="100%">' +
                   '<tbody>' +
                       '<tr>' +
                           '<td align="center" valign="top">' +
                           '<table border="0" cellpadding="15" cellspacing="0" style="border-collapse: collapse; border-spacing: 0; font-size: 0; width: 100%; margin: 0; border: 0;" width="100%">' +
                               '<tbody>' +
                                   '<tr>' +
                                       '<td bgcolor="#fcfcfc" style="font-size: 0; width: 100%; margin: 0;" width="100%">' +
                                       '<table border="0" cellpadding="8" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;">' +
                                           '<tbody>' +
                                               '<tr>' +
                                                   '<td bgcolor="#e9e9e9" style="color: #666666; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 12px; letter-spacing: .015em; line-height: 1; text-transform: uppercase;">';
                                                   
        //<%= article.type %>
        export const _html4 = '</td>' +
                                               '</tr>' +
                                           '</tbody>' +
                                       '</table>' +
                                       '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                                           '<tbody>' +
                                               '<tr>' +
                                                   '<td height="8" style="font-size: 0;">&nbsp;</td>' +
                                               '</tr>' +
                                           '</tbody>' +
                                       '</table>' +
                                       '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                                           '<tbody>' +
                                               '<tr>' +
                                                   '<td style="color: #1330bf; margin: 0; padding: 0; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-weight: bold; text-decoration: none; font-size: 19px; line-height: 1.15;"><a href="';
                //<%= article.url %>
                export const _html5='?ltflags=mailer&amp;utm_medium=email&amp;utm_campaign=extremism-watch-t161&amp;utm_source=newsletter" style="color: #1330bf; margin: 0; padding: 0; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-weight: bold; text-decoration: none; font-size: 19px; line-height: 1.15;">';
                
                //<%= article.title %>
                export const _html6= '</a></td>' +
                                               '</tr>' +
                                           '</tbody>' +
                                       '</table>' +
                                       '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                                           '<tbody>' +
                                               '<tr>' +
                                                   '<td height="8" style="font-size: 0;">&nbsp;</td>' +
                                               '</tr>' +
                                           '</tbody>' +
                                       '</table>' +
                                       '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                                           '<tbody>' +
                                               '<tr>' +
                                                   '<td style="color: #999999; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 13px; letter-spacing: .01em; padding: 0; margin: 0; text-transform: uppercase;">';
            //<%= article.pubtime %> | <%= article.author %>
            
            export const _html7 = '</td>' +
                                               '</tr>' +
                                           '</tbody>' +
                                       '</table>' +
                                       '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                                           '<tbody>' +
                                               '<tr>' +
                                                   '<td height="6" style="font-size: 0;">&nbsp;</td>' +
                                               '</tr>' +
                                           '</tbody>' +
                                       '</table>' +
                                       '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                                           '<tbody>' +
                                               '<tr>' +
                                                   '<td style="color: #444444; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; text-decoration: none; font-size: 15px; padding: 0px; margin: 0; line-height: 1.35;">';
    //<%= article.description %>
    export const _html8 = '</td>' +
                                               '</tr>' +
                                           '</tbody>' +
                                       '</table>' +
                                       '</td>' +
                                   '</tr>' +
                               '</tbody>' +
                           '</table>' +
                           '</td>' +
                       '</tr>' +
                   '</tbody>' +
               '</table>' +
               '</div>' +
               '<!--[if mso]>' +
                       '</td>' +
                       '</tr>' +
                       '</table>' +
                       '<![endif]--></td>' +
           '</tr>' +
       '</tbody>' +
   '</table>' +
   '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
       '<tbody>' +
           '<tr>' +
               '<td height="30" style="font-size: 0;">&nbsp;</td>' +
           '</tr>' +
       '</tbody>' +
   '</table>' +
   '<!--[if mso]>' +
           '</td>' +
           '</tr>' +
           '</table>' +
           '<![endif]--></td>' +
'</tr>';