/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apie5624249",
            "endpoint": "https://ap77f76o86.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1f8b328f-8dea-4acf-8779-ce52b64a8c5b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_xfiSmT4Yb",
    "aws_user_pools_web_client_id": "5gkl7nt47lar62mlmkdcmq16or",
    "oauth": {}
};


export default awsmobile;
