import moment from 'moment-timezone'

const _today = moment().tz("America/New_York").format("MMM DD, YYYY");

export const _header = '<head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1.0"><title></title></head>' +
'<style type="text/css">' +
'    body { background-color : #f2f2f2; margin: 0; } ' +
'   img { display: block;} ' +
'</style>' +
'<body><center>' +
'<table bgcolor="f2f2f2" border="0" cellpadding="0" cellspacing="0" height="100%" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
 '   <tbody>';

 export const _header_tr = '<tr>' +
 '       ' +
 '           <td align="center" height="100%" valign="top" width="100%">' +
 '                  <!--[if mso]>' +
 '                   <table align="center" border="0" cellspacing="0" cellpadding="0" width="640">' +
 '                   <tr>' +
 '                   <td align="center" valign="top" width="640">' +
 '                   <![endif]-->' +
 '           <table align="center" border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
 '               <tbody>' +
 '                   <tr>' +
 '                       <td align="center" bgcolor="1330bf" style="max-width: 640px; font-size: 0; overflow: hidden;" valign="top">' +
 '                       <table align="center" border="0" cellpadding="8" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
 '                           <tbody>' +
 '                               <tr>' +
 '                                   <td align="center" height="30"><a href="https://www.voanews.news?utm_medium=email&amp;utm_campaign=extremism-watch-t161&amp;utm_source=newsletter" style="color: #fff; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 24px; font-weight: bold;"><img alt="VOA" border="0" height="30" src="https://gdb.voanews.com/8CB55872-2155-44C0-9CD2-9ED59B06D589.png" style="display: block; border: 0;" width="71" /></a></td>' +
 '                               </tr>' +
 '                           </tbody>' +
 '                       </table>' +
 '                       </td>' +
 '                   </tr>' +
 '               </tbody>' +
 '           </table>' +
 '           <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
 '               <tbody>' +
 '                   <tr>' +
 '                       <td height="30" style="font-size: 0;">&nbsp;</td>' +
 '                   </tr>' +
 '               </tbody>' +
 '           </table>' +
 '           <!--[if mso]>' +
 '                   </td>' +
 '                   </tr>' +
 '                   </table>' +
 '                   <![endif]--></td>' +
 '       </tr>' +
 '       <tr>' +
 '           <td align="center" height="100%" valign="top" width="100%"><!--[if mso]>' +
 '                   <table align="center" border="0" cellspacing="0" cellpadding="0" width="640">' +
 '                   <tr>' +
 '                   <td align="center" valign="top" width="640">' +
 '                   <![endif]-->' +
 '           <table align="center" border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
 '               <tbody>' +
 '                   <tr>' +
 '                       <td align="center" bgcolor="f2f2f2" valign="top">' +
 '                       <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
 '                           <tbody>' +
 '                               <tr>' +
 '                                   <td width="16">&nbsp;</td>' +
 '                                   <td align="center" style="color: #444444; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 42px; font-weight: normal; padding: 0; margin: 0; line-height: 1;">VOA Extremism Watch</td>' +
 '                                   <td width="16">&nbsp;</td>' +
 '                               </tr>' +
 '                           </tbody>' +
 '                       </table>' +
'                        <table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
 '                           <tbody>' +
 '                               <tr>' +
 '                                   <td height="8" style="font-size: 0;">&nbsp;</td>' +
 '                               </tr>' +
 '                           </tbody>' +
 '                       </table>' +
                        '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                            '<tbody>' +
                                '<tr>' +
                                    '<td width="16">&nbsp;</td>' +
                                    '<td align="center" style="font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; margin: 0; padding: 0; font-size: 14px; font-weight: normal; letter-spacing: .015em; line-height: 1; text-decoration: none; text-transform: uppercase; color: #cccccc;">&mdash; <a href="[-ViewInBrowser-]" style="font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; margin: 0; padding: 0; font-size: 14px; font-weight: normal; letter-spacing: .015em; line-height: 1; text-align: center; text-decoration: none; text-transform: uppercase; color: #999999;">' + _today + '</a> &mdash;</td>' +
                                    '<td width="16">&nbsp;</td>' +
                                '</tr>' +
                            '</tbody>' +
                        '</table>' +
                        '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                            '<tbody>' +
                                '<tr>' +
                                    '<td height="8" style="font-size: 0;">&nbsp;</td>' +
                                '</tr>' +
                            '</tbody>' +
                        '</table>' +
                        '</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
            '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                '<tbody>' +
                    '<tr>' +
                        '<td height="30" style="font-size: 0;">&nbsp;</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
            '<!--[if mso]>' +
                    '</td>' +
                    '</tr>' +
                   '</table>' +
                    '<![endif]--></td>' +
        '</tr>';

export const _footer_tr = '<tr>' +
'<td align="center" height="100%" valign="middle" width="100%"><!--[if mso]>' +
        '<table align="center" border="0" cellspacing="0" cellpadding="0" width="640">' +
        '<tr>' +
        '<td align="center" valign="middle" width="640">' +
        '<![endif]-->' +
'<table align="center" border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
    '<tbody>' +
        '<tr>' +
            '<td align="center" bgcolor="e9e9e9" style="max-width: 640px; font-size: 0; overflow: hidden; border-top-width: 1px; border-top-color: #cccccc; border-top-style: solid; border-bottom-width: 1px; border-bottom-color: #cccccc; border-bottom-style: solid;" valign="middle">' +
            '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                '<tbody>' +
                    '<tr>' +
                        '<td height="16" style="font-size: 0;">&nbsp;</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
            '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                '<tbody>' +
                    '<tr>' +
                        '<td align="center" style="color: #666666; margin: 0; padding: 0; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 15px; font-weight: 400; line-height: 1; text-transform: uppercase;">Engage with VOA</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
            '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                '<tbody>' +
                    '<tr>' +
                        '<td height="8" style="font-size: 0;">&nbsp;</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
            '<table border="0" cellpadding="8" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;">' +
                '<tbody>' +
                    '<tr>' +
                        '<td height="44" style="" width="44"><a href="https://www.facebook.com/VOAextremism/" style="color: #1330bf; margin: 0; padding: 0; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 13px; font-weight: bold; line-height: 1; text-decoration: none;"><img alt="Facebook" border="0" height="44" src="https://gdb.voanews.com/0BAA13B7-EE2D-4266-8134-FFB183B2FC89.png" style="display: block; border: 0;" width="44" /></a></td>' +
                        '<td height="44" style="" width="44"><a href="https://twitter.com/VOA_Extremism" style="color: #1330bf; margin: 0; padding: 0; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 13px; font-weight: bold; line-height: 1; text-decoration: none;"><img alt="Twitter" border="0" height="44" src="https://gdb.voanews.com/DBB4A9F8-7EF3-4A25-9DD8-319824FAD909.png" style="display: block; border: 0;" width="44" /></a></td>' +
                        '<td height="44" style="" width="44"><a href="https://www.youtube.com/voaextremism" style="color: #1330bf; margin: 0; padding: 0; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 13px; font-weight: bold; line-height: 1; text-decoration: none;"><img alt="YouTube" border="0" height="44" src="https://gdb.voanews.com/B0C4A936-D522-46A5-B2B9-74DF07F3D2D8.png" style="display: block; border: 0;" width="44" /></a></td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
            '<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                '<tbody>' +
                    '<tr>' +
                        '<td height="8" style="font-size: 0;">&nbsp;</td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
            '</td>' +
        '</tr>' +
    '</tbody>' +
'</table>' +
'<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
    '<tbody>' +
        '<tr>' +
            '<td height="30" style="font-size: 0;">&nbsp;</td>' +
        '</tr>' +
    '</tbody>' +
'</table>' +
'<!--[if mso]>' +
        '</td>' +
        '</tr>' +
        '</table>' +
        '<![endif]--></td>' +
'</tr>' +
'<tr>' +
'<td align="center" height="100%" valign="top" width="100%"><!--[if mso]>' +
        '<table align="center" border="0" cellspacing="0" cellpadding="0" width="640">' +
        '<tr>' +
        '<td align="center" valign="top" width="640">' +
        '<![endif]-->' +
'<table align="center" border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
    '<tbody>' +
        '<tr>' +
            '<td align="center" bgcolor="f2f2f2" style="max-width: 640px; font-size: 0; overflow: hidden;" valign="top">' +
            '<table border="0" cellpadding="8" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
                '<tbody>' +
                    '<tr>' +
                    '</tr>' +
                    '<tr>' +
                        '<td align="center" style="color: #666666; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 15px; font-weight: normal; line-height: 1;"><a href="http://voa.goolara.net/subscribe.aspx?t=161&amp;utm_medium=email&amp;utm_campaign=extremism-watch-t161&amp;utm_source=newsletter" style="color: #1330bf; text-decoration: none;">Subscribe to this newsletter.</a></td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td align="center" style="color: #666666; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 15px; font-weight: normal; line-height: 1;"><a href="[-TopicUnsubscribeUrl-]" style="color: #999999; text-decoration: none;">Need to unsubscribe?</a></td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td align="center" style="color: #666666; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 15px; font-weight: normal; line-height: 1;"><strong>Voice of America</strong><br />' +
                        '330 Independence Avenue, SW<br />' +
                        'Washington, DC 20237</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td align="center" style="color: #666666; font-family: Helvetica, Tahoma, Verdana, Arial, sans-serif; font-size: 15px; font-weight: normal; line-height: 1;"><a href="mailto:voanews@voanews.com" style="color: #1330bf; text-decoration: none;">voanews@voanews.com</a></td>' +
                    '</tr>' +
                '</tbody>' +
            '</table>' +
            '</td>' +
        '</tr>' +
    '</tbody>' +
'</table>' +
'<table border="0" cellpadding="0" cellspacing="0" style="border-collapse: collapse; border-spacing: 0;" width="100%">' +
    '<tbody>' +
        '<tr>' +
            '<td height="30" style="font-size: 0;">&nbsp;</td>' +
        '</tr>' +
    '</tbody>' +
'</table>' +
'<!--[if mso]>' +
        '</td>' +
        '</tr>' +
        '</table>' +
        '<![endif]--></td></tr>';
        
export const _footer =        '</tbody></table></center></body>';