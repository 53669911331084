import React, { Component } from 'react';
import {Container, Card, Input, Divider} from 'semantic-ui-react'

import Amplify, { API } from 'aws-amplify';
import aws_exports from '../aws-exports';
import ArticleCard from './ArticleCard';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import PangeaNewsletter from './PangeaNewsletter'
import {randomstring} from 'randomstring-js'
import saveAs from 'save-as'

import ls from 'local-storage'

Amplify.configure(aws_exports);

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      message: '',       
      components: [],
      isDisabled: false,
      items: [], 
      text: '',
      author: '',
      description: '',
      imgstr: '',
      pubtime: '',
      title: '',
      type: '',
      rtnurl: '',
      key: 0,
      detailedElement: {},
      pangeaNewsletterFlag: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.scrape = this.scrape.bind(this);
    this.mainRef = React.createRef();
    this.clearList = this.clearList.bind(this);
    this.clearThisItem = this.clearThisItem.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.togglePangeaNewsletter = this.togglePangeaNewsletter.bind(this);
    this.downloadHtml = this.downloadHtml.bind(this);
  }

  componentDidMount() {
      this.setState({items: ls.get('items') || []});
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    //console.log(newItems)

    this.setState({
      items: newItems
    });
    ls.set('items', this.state.items)
  }

  handleChange(event) {
    this.setState({url: event.target.value});
  }

  clearList(event) {
    this.setState({items: []})
    ls.set('items', [])
  }

  clearThisItem(id) {
    // get copy of items
    var mutateItems = [...this.state.items]

    // find id in array
    var delIndex = -1;
    this.state.items.map(function(currentValue, index) {
      if (currentValue.id === id) {
        delIndex = index
        return index
      }
      return -1
    })
    
    if (delIndex >= 0) {
      mutateItems.splice(delIndex, 1)
    }
    
    this.setState({items: mutateItems})
    ls.set('items', mutateItems)
  }  

  togglePangeaNewsletter() {
    this.setState({pangeaNewsletterFlag: !this.state.pangeaNewsletterFlag})
  }

  downloadHtml() {
    // const fs = require('fs')
    const content = document.getElementById('newsletter').innerHTML

    const filename = randomstring({
      length: 16
    }).concat('.html')

    let blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, filename)
  }

  async scrape () {

    let apiName = 'apie5624249';
    let path = '/scrape';
    let url = this.state.url;

    function getData() { 
      const myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        'queryStringParameters': {
          'url': url
        }
      };
    
      return API.get(apiName, path, myInit);
    }  
    const response = await getData();

    return response

  };

  handleSubmit(event) {
    event.preventDefault();

    // disable button
    this.setState({isDisabled: true});
    this.setState({ message: ''});

    if (this.state.url.length === 0) {
      this.setState({isDisabled: false});
      return false
    } 

    this.scrape().then((response) => {
      this.setState({isDisabled: false});

      if (response.rtncode === 0) {
        const newItem = {
          title: response.title,
          imgstr: response.imgstr,
          pubtime: response.pubtime,
          type: response.type,
          author: response.author,
          description: response.description,
          rtnurl: response.url,
          id: Date.now(),
          clearThisItem: this.clearThisItem
        };
        this.setState(state => ({
          items: state.items.concat(newItem),
          url: ''
        }));
        ls.set('items', this.state.items)
      } else {
        this.setState({ message: response.message});
      }
      this.mainRef.current.focus();
    });
    
  }

    render() {  
    
    if (this.state.pangeaNewsletterFlag) {
      
      // show the formatted webpage in the Pangea newsletter format
      return  <Container>
                <button className="ui button" onClick={this.togglePangeaNewsletter}>Return to List</button>
                &nbsp;&nbsp;
                <button className="ui button" onClick={this.downloadHtml}>Download HTML Source</button>
                <Divider></Divider>
                <PangeaNewsletter items={this.state.items}></PangeaNewsletter>
              </Container>

    } else {

      // show the Publish buttons when there is an article to publish
      var showPublishButton = 
        <div>
          <br/>
          <button className="ui button" onClick={this.togglePangeaNewsletter} >Format for Newsletter</button>
            &nbsp;&nbsp;
          <button className="ui button" onClick={this.clearList}>Delete All</button> &nbsp;&nbsp;
          <span className="ui header">Articles will be used for the Pangea Newsletter and can be reordered by dragging and dropping</span>
          <Divider></Divider>
        </div>
      if (this.state.items.length === 0) showPublishButton = ''

      return <Container>
                <div style={{width: '100%', display: 'flex'}}>
                  <Input style={{minWidth: '87%', paddingLeft: 0 }} placeholder='Enter the VOAnews.com article URL' 
                              value={this.state.url} onChange={this.handleChange} />
                              &nbsp;&nbsp;
                    <button type="button" value="Get Article" className="ui button" style={{minWidth: '9%' }}  onClick={this.handleSubmit} disabled={this.state.isDisabled}>Get Article</button>
                </div>
                {showPublishButton}
                <div>{this.state.message}</div>
                <Card.Group>
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="articles" direction="horizontal">
                        {(provided) => (
                          <ul className="articles" {...provided.droppableProps} ref={provided.innerRef}> {  
                            this.state.items.map( function(item, index) { 
                              return (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                  {(provided) => (
                                    <li key={item.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                      <ArticleCard item={item} id={item.id} index={index} clickHandler={item.clearThisItem}/>
                                    </li>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </ul>
                        )}
                    </Droppable>
                  </DragDropContext>
                </Card.Group>
                <a href="#end" ref={this.mainRef} >&nbsp;</a>
              </Container>
    }
  }
}

export default Home;
