import React, { Component } from "react";
import { Card, Image, Icon } from 'semantic-ui-react';
import ShowMoreText from 'react-show-more-text';

class ArticleCard extends Component {
  render(props) {
    const leadArticle = this.props.index === 0 ? 'Lead Article': '';
    return <Card style={{ width: "200px", }}>
      <Card.Content style={{ color: 'red', textAlign: 'center', minHeight: "12px"}}>{leadArticle}</Card.Content>
      <Image src={this.props.item.imgstr} wrapped ui={false} />
      <Card.Content>
        <Card.Header>{this.props.item.title}</Card.Header>
      </Card.Content>
      <Card.Content>
        <Card.Meta>
          <span>{this.props.item.author}</span>
          <span className='date'>{this.props.item.pubtime}</span>
        </Card.Meta>
        <ShowMoreText>
          <Card.Description>
            {this.props.item.description}
          </Card.Description>
        </ShowMoreText>
      </Card.Content>
      <Card.Content >
        <span data-tooltip="Remove this Article" data-position="left center" data-variation="tiny">
        </span>
      </Card.Content>
    </Card>
  }
}

export default ArticleCard;