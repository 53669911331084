import { Component } from "react"
import { _header_tr, _header, _footer, _footer_tr } from './PangeaFormat/PangeaHeaderFooterConstants'
import {_leadhtml1, _leadhtml2, _leadhtml3, _leadhtml4, _leadhtml5, _leadhtml6, _leadhtml7,_leadhtml8, _leadhtml9, _leadhtml10} from './PangeaFormat/LeadArticleConstants'
import {_html1, _html2, _html3, _html4, _html5, _html6, _html7,_html8} from './PangeaFormat/ArticleConstants'

class PangeaNewsletter extends Component {

    render(props) {   
        // build the newsletter web page

        var webpage = _header + _header_tr;
        this.props.items.map( (item, index) => {
            if (index === 0) {
                webpage +=  _leadhtml1 + item.rtnurl + _leadhtml2 + item.imgstr + _leadhtml3 + item.type  + _leadhtml4 + item.rtnurl + _leadhtml5 + item.title + _leadhtml6 +  item.pubtime + ' | ' + item.author + _leadhtml7 + item.description + _leadhtml8 + item.rtnurl+ _leadhtml9 + item.rtnurl + _leadhtml10 
            } else {
                webpage += _html1 + item.rtnurl + _html2 + item.imgstr + _html3 + item.type  + _html4 + item.rtnurl + _html5 + item.title + _html6 +  item.pubtime + ' | ' + item.author + _html7 + item.description + _html8  
            }
            return null
        });
        webpage += _footer_tr + _footer;

  
        return ( 
        <div id="newsletter" style={{backgroundColor: '#f2f2f2'}}>
            
            <html dangerouslySetInnerHTML={{__html: webpage }} />
            
        </div>
        )
    }
}

export default PangeaNewsletter