import './App.scss';
import Home from './components/Home';

import {Header, Divider, Container} from 'semantic-ui-react'

import { AmplifyAuthenticator, AmplifySignIn, AmplifySignOut} from '@aws-amplify/ui-react';
const divStyle = {
  minHeight: 600
};

const App = () =>
 
(
  <div style={divStyle}>
    <Header as='h1' textAlign='center'>Voltron-Pangea Newsletter Tool</Header>
    <AmplifyAuthenticator>
      <AmplifySignIn headerText="Sign In" slot="sign-in"/>
      <div>
        <Home />
        <br/>
      </div>
      <Container>
      <Divider></Divider>
      <AmplifySignOut/>
      </Container>
    </AmplifyAuthenticator>
  </div>
);

export default App;